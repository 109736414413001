<template>
  <div class="level">
    <div class="level-item">
        <div class="card">
        <header class="card-header has-background-primary">
            <h4 class="card-header-title is-centered title is-4 has-text-white">Subscription ACH Payment Setup</h4>
        </header>
    <div class="card-content" v-if="store.id && store.subscriptionStatus=='NEW'"> 
        <b-message title="Thank you for submitting the Bank Account information" type="is-info" has-icon  aria-close-label="Close message">
            2 deposits of less than $1 will be deposited to your account within 3 days of submitting the bank account information.<br>
            Please check your bank account transactions and note both deposit amounts and enter it below.
            </b-message>
            <div class="columns">
                <form ref="form" @submit.prevent="handleSubmit">
                <div class="column">
                    
                    <b-field label="Deposit 1 (no decimals)">

                        <b-numberinput
                            icon="file-invoice-dollar"
                            min="1" 
                            max="99"
                            required
                            step=1
                            v-model="newbank.deposit1"
                        >
                        </b-numberinput>

                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Deposit 2 (no decimals)">
                        <b-numberinput 
                            icon="file-invoice-dollar"
                            min="1" 
                            max="99"
                            required
                            step=1
                            v-model="newbank.deposit2"
                        >
                        </b-numberinput>

                    </b-field>
                </div>

                <b-message class="is-info-light" :closable="isClosable">
                    Please enter the exact amounts of the 2 deposits that was made to your bank account.
                </b-message>
                <b-button
                    type="is-primary"
                    icon-pack="fas"
                    icon-left="paper-plane"
                    :disabled="isFormNotComplete"
                    @click.prevent="handleSubmit">
                    Save
                </b-button>
                </form>
            </div>        

    </div>

    <div class="box" v-else-if="store.id && store.subscriptionStatus=='ACTIVE'">
        <b-notification class="is-info" :closable="isClosable">
            Bank account has already been verified and the subscription is active. Thank you for your subscription.<br>
            If you need to change the bank account, please contact support@veecli.com.
        </b-notification>
    </div>

    <div class="box" v-else-if="store.id && store.subscriptionStatus=='PAYMENT_VERIFIED'">
        <b-notification class="is-success" :closable="isClosable">
            Bank account verfication complete! Thank you!
        </b-notification>        
    </div>

    <div class="box" v-else-if="store.id && store.subscriptionStatus=='EXIPRED'">
        <b-notification class="is-warning" :closable="isClosable">
            Subscription has been expired.  Please contact support.
        </b-notification>        
    </div>
    <div class="box" v-else-if="store.id && store.subscriptionStatus==null">
        <b-message title="Configure ACH Payment for your subscription" type="is-info" has-icon  aria-close-label="Close message">
            <ul>
                <li>
                    Step 1. Complete the following form with your bank account Information.
                </li>
                <li>
                    Step 2. Within 2 days, we will make <u>2 deposits of amounts less than $0.99</u> to your bank account.
                </li>
                <li>
                    Step 3. Log in to your bank account in 2 or 3 days and note the exact deposit amounts.
                </li>
                <li>
                    Step 4. Log back in here and <u>enter the same deposit amounts</u> you noted from step 3.
                </li>
            </ul>
        </b-message>

        <div class="modal-card scrollable" style="width: auto">
            <form action="" class="box" @submit.prevent="submitForm">
            <header class="modal-card-head">
            <p class="modal-card-title">Bank Account Informations</p>
            </header>
            <section class="modal-card-body">        
                <div class="columns">
                    <div class="column">
                    <b-field label="Account Holder">
                        <b-input type="text"
                            icon="user-tie"
                            v-model="bank.accountHolderName"
                            maxlength="30" 
                            required                  
                            >
                        </b-input>	                               
                    </b-field>
                    </div>
                    <div class="column">
                    <b-field label="Account Type">
                        <b-select
                            required
                            icon="map"
                            v-model="bank.accountHolderType">
                            <option
                                v-for="type in bankAccountHolderTypes"
                                :value="type[0]"
                                :key="type[0]">
                                {{ type[1] }}
                            </option>
                        </b-select>                               
                    </b-field>  
                    </div>
                    <div class="column">
                    <b-field label="Country">
                        <b-select
                            icon="flag"
                            placeholder="Select Country" 
                            v-model="bank.countryCode" 
                            required>
                            <option
                                v-for="country in countries"
                                :value="country[0]"
                                :key="country[0]">
                                {{ country[1] }}
                            </option>
                        </b-select>		                               
                    </b-field>
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                    <b-field label="Bank Routing Number">
                        <b-input type="text"
                            icon="university"
                            v-model="bank.routingNumber"
                            maxlength="30" 
                            required                  
                            >
                        </b-input>	                               
                    </b-field>
                    </div>
                    <div class="column">
                    <b-field label="Account Number">
                        <b-input type="text"
                            icon="money-check"
                            v-model="bank.accountNumber"
                            maxlength="30" 
                            required                  
                            >
                        </b-input>	                               
                    </b-field>     
                    </div>
                </div>
                <b-message class="is-info-light" :closable="isClosable">
                    <ul>
                        <li>
                            Please enter your bank account information.
                        </li>
                        <li>
                            Your subscription dues will be automatically debited to the account on 1st of every month.
                        </li>
                    </ul>
                </b-message>

            </section>

            <footer class="modal-card-foot">
                <b-button
                    type="is-primary"
                    icon-pack="fas"
                    icon-left="paper-plane"
                    :disabled="isFormNotComplete"
                    @click.prevent="handleSubmit">
                    Save
                </b-button>
            </footer>
            </form>    
            </div>    
         </div>
        </div>
    </div>
  </div>
</template>

<script>

import UserStore from "../../../../store/UserStore"
import axios from 'axios'
import router from "../../../../router"

export default {

    metaInfo: {
        title: 'Bank Account Verification Status'
    },

    data() {
        return {
            store: {},
            user: {},
            saveButtonEnabled: true,
            newbank: {
                createSubscription: true,
                deposit1: 0,
                deposit2: 0,
            },
            bank: {
                accountHolderName: "",
                accountHolderType: "company",
                countryCode: "US",
                routingNumber: "",
                accountNumber: "",
                currencyCode: "USD"
            },
            isDisabled: true,
            isClosable: false,
            subscriptionStatus: null,
            states: [ [ 'FL', 'Florida'], [ 'IL', 'Illinois'], [ 'IN', 'Indiana'],  [ 'MI', 'Michigan'], [ 'WI', 'Wisconsin']],
            countries: [ ['US', 'USA'], ['CA','Canada'], ['MX','Mexico']],
            zones: [ ['CST','Central Time'], ['EST', 'Eastern Time'], ['MST','Mountain Time'], ['PST','Pacefic Time']],
            bankAccountHolderTypes: [ [ "company", "Company"], [ "individual", "Individual"]],


        }
    },

    methods: {

        fetchStore() {
			if (  UserStore.getters.getActiveStore.storeId ) {
				axios.get('/sapi/stores/' + UserStore.getters.getActiveStore.storeId, {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken					},				
                    })
				.then( response => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                    this.store = response.data.data

                    this.dailyRegisterClosingTime = new Date(2018, 11, 24,this.store.reconcilationClosingHours, this.store.reconcilationClosingMinutes)
				})
				.catch( (err) => {
					if (process.env.VUE_APP_DEBUG == 'true') console.log("Error",err)
				})				
			}
        },

        handleSubmit() {

            switch (this.store.subscriptionStatus) {
                case null:
                    this.saveACHAccount()
                    break
                
                case "NEW":
                    this.verifyDepositAmounts()
                    break

                default:
                    console.log("Data cannot be saved", this.store.subscriptionStatus)

            }
        },

        saveACHAccount() {

            if (!this.isFormNotComplete) {

                this.saveButtonEnabled=false

                var bank_url = process.env.VUE_APP_API_ROOT_URL + "/stores/" +  this.user.activeStore + "/subscription/account"

                axios.post(bank_url, 
                    this.bank,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },
					}                
                )
                .then( () => {
                    UserStore.commit('fetchStore')
                    this.store.subscriptionStatus = 'NEW'
                    router.push({ path: '/home' })
                })
                .catch( (err) => {
                    if (err.response) {
                        console.log(err.response.data)
                        console.log(err.response.status)
                        console.log(err.response.headers)
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    }
                })
            }

        },

        verifyDepositAmounts() {

            console.log("Posting Data")

            if (!this.isFormNotComplete) {

                this.saveButtonEnabled=false
                
                var bank_url = process.env.VUE_APP_API_ROOT_URL + "/stores/" +  this.user.activeStore + "/subscription/verify"

                axios.post(bank_url, 
                    this.newbank,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },
					}                
                )
                .then( () => {
                    UserStore.commit('fetchStore')
                    this.store.subscriptionStatus = 'PAYMENT_VERIFIED'
                    router.push({ path: '/home' })
                })
                .catch( (err) => {
                    if (err.response) {
                        console.log(err.response.data)
                        console.log(err.response.status)
                        console.log(err.response.headers)
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    }
                })
            }            
        },
        
    },

    computed: {

        getActiveStore() {
            if (UserStore.getters.getActiveStore) {
                return UserStore.getters.getActiveStore
            } else {
                return false
            }
        },

        // getUser() {
        //     return UserStore.getters.getUser
        // }, 

        isFormNotComplete() {
            if (this.saveButtonEnabled && this.bank.accountHolderName  && this.bank.routingNumber && this.bank.accountNumber ) {
                console.log("Account Data is good")
                return false
            }
            if (this.saveButtonEnabled && this.newbank.deposit1 > 0 && this.newbank.deposit1 < 100 && this.newbank.deposit2 > 0 && this.newbank.deposit2 < 100) {
                if (!isNaN(this.newbank.deposit1) && !isNaN(this.newbank.deposit2))  {
                    console.log("Verification Data is good")
                    return false
                }            
            }
            return true
        },

        isBankAccountValid() {
            var bankValid = true
            for (const [key, value] of Object.entries(this.bank)) {
                if (value == "" && key != 'accountNumberLastFour') {
                    bankValid = false
                }
            }
            return bankValid
        },

    },

    mounted() {
        this.user = this.getUser()
        this.fetchStore()
    }
}
</script>